<template>
  <div>
    <modal className="mr-0 px-10 w-full md:w-420px" ref="modal">
      <h5 class="mb-3">Debit {{ staff.name }}'s Cash Wallet</h5>
      <div class="flex flex-col gap-1 mb-10 text-gray-700">
        <p>
          This process deducts the amount you specify below from the user's cash
          wallet.
        </p> 
        <p>The user will be notified accordingly.</p>
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <form @submit.prevent="submit">
        <form-group
          v-model="form.data.amount.value"
          :form="form"
          name="amount"
          type="money"
        >
          Amount to debit
        </form-group>
        <form-group
          v-model="form.data.reason.value"
          :form="form"
          name="reason"
          type="select"
          :options="reasons"
        >
          Reason for debit
        </form-group>
        <button
          type="submit"
          class="btn btn-blue btn-md w-full"
          :disabled="form.loading"
        >
          <sm-loader-white v-if="form.loading" />
          <span v-else>Confirm Debit</span>
        </button>
      </form>
    </modal>
    <modal></modal>
  </div>
</template>

<script>
export default {
  props: {
    staff: Object,
    wallet: Object,
  },
  data() {
    const form = this.$options.basicForm(['amount', 'reason']);
    const reasons = [
      // { title: 'Select Reason', value: '' },
      {
        title: 'Credit Card Repayment',
        value: 'Credit Card Repayment',
      },
      {
        title: 'Loan Repayment',
        value: 'Loan Repayment',
      },
      {
        title: 'Partial Credit Card Repayment',
        value: 'Partial Credit Card Repayment',
      },
      {
        title: 'Partial Loan Repayment',
        value: 'Partial Loan Repayment',
      },
      {
        title: 'Equity Contribution Payment',
        value: 'Equity Contribution Payment',
      },
      {
        title: 'Airtime Payment',
        value: 'Airtime Payment',
      },
      {
        title: 'Internet/Data Subscription',
        value: 'Internet/Data Subscription',
      },
      {
        title: 'Electricity Payment',
        value: 'Electricity Payment',
      },
      {
        title: 'Cable TV Payment',
        value: 'Cable TV Payment',
      },
      {
        title: 'Flight Booking',
        value: 'Flight Booking',
      },
      {
        title: 'Tokenisation Fee',
        value: 'Tokenisation Fee',
      },
      {
        title: 'Product Purchase',
        value: 'Product Purchase',
      },
      {
        title: 'Matured Investment Interest',
        value: 'Matured Investment Interest',
      },
      {
        title: 'Administrative Charges',
        value: 'Administrative Charges',
      },
      {
        title: 'Undeducted Successful Transaction',
        value: 'Undeducted Successful Transaction',
      }
    ];
    return { form, reasons };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    async submit() {
      if (!this.validateForm(this.form)) return false;

      this.form.error = null;
      this.form.loading = true;
      await this.sendRequest('admin.users.cash.debit', {
        data: {
          ...this.getFormData(this.form),
          wallet_id: this.wallet.id,
          user_id: this.staff.id,
        },
        success: () => {
          this.close();
          this.$success({
            title: 'Operation Successful',
            body: 'User wallet debited',
            button: 'Okay'
          });
          this.form = this.resetForm(this.form);
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });
      this.form.loading = false;
    },
    open() {
      this.$refs.modal.open();
    },
  },
};
</script>
